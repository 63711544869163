import React from "react";
import { graphql } from "gatsby";
import { Grid, List, Table } from "semantic-ui-react";
import Helmet from "react-helmet";
import {
  PageLayout,
} from "@fishrmn/fishrmn-components";

import Layout from "../components/Layout";

export default class extends React.PureComponent {
  render() {
    const businessData = this.props.data.allFisherman.edges[0].node;
    const data = { ...businessData };

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Privacy Policy</title>
          <meta name="description" content="" />
        </Helmet>
        <PageLayout
          hero={null}
        >
          <Grid
            stackable
            className="component-section-container privacy-policy"
            verticalAlign={"middle"}
            textAlign={"left"}
            centered
          >
            <Grid.Column>
            <h2>Egg N Bird Rewards Terms</h2>
            <div className="terms-section">
                <h3>Egg N Bird Rewards Program</h3>

                <p>
                  Here's how the Egg N Bird Rewards Program works: You earn Eggs by using your Egg N Bird Rewards card or account when making Egg N Bird purchases. (When we use the term "Card" in this program description, we mean both physical and digital cards.) It's simple, for every dollar spent, we'll reward you with two (2) Egg N Bird Rewards Eggs. After each time you earn 100 Eggs, those 100 Eggs are automatically converted to a $5.00 reward redeemable on future purchases!
                  </p>
                  <p>
                  You will start on our Mild Tier level. You can move up to our Hot Level and then all the way to the Eggstra Hot Level. The higher you go, the bigger and more exclusive rewards you'll earn! The tier level you earn in one year is good for the remainder of that year and for all of the next year.
                  </p>
                  <p>
                  To start earning Eggs, simply make a purchase with your registered Egg N Bird Rewards account. Your account must be registered to turn those Eggs into rewards. To register your Egg N Bird Rewards account, visit eggnbird.com/rewards or register using the mobile app!
                  </p>
              </div>

              <div className="terms-section">
                <h3>Start Earning Eggs and Rewards</h3>
                <p>Earn your first delicious reward just by registering! Right away, you qualify for 50 Eggs and qualify to receive a birthday reward! Keep earning Eggs by using your registered Egg N Bird Rewards account at participating Egg N Bird Stores or through Egg N Bird online ordering. For every dollar you spend, your Egg N Bird Rewards account will earn 2 Eggs. As you accumulate Eggs, you earn bigger benefits. There are three reward levels: Mild, Hot and Eggstra Hot. We keep track of your Eggs for each year you participate. Your first year starts on the day you register ("your Registration Date").</p>
                <p>By earning 200 Yearly Eggs, you move up from Mild Level to Hot Level. That qualifies you for Quarterly Bonus Eggs, a Birthday Reward Upgrade and a Half Birthday Reward. </p>
                <p>After earning 600 Yearly Eggs, you move up from Hot Level to Eggstra Hot Level. That qualifies you for Quarterly Bonus Eggs, a Birthday Reward Upgrade, a Half Birthday Reward, 2x Eggs every 2nd of the month, and exclusive surprise offers throughout the year.</p>
                <p>After the initial registration reward, Bonus Eggs earned from special offers or campaigns like 2x Eggs, 3x Eggs, or 25 Bonus Eggs, do not qualify as Yearly Eggs and moving closer to a new tier, but do get you closer to earning your next reward.</p>
              </div>

              <div className="terms-section">
                <h3>Other Terms and Conditions</h3>
                <p>
                Rewards may be redeemed at participating Egg N Bird Stores only or through Egg N Bird online ordering on our website or mobile app. Each birthday reward must be used within the specific four-week period provided to participant by email or as displayed on their account balance, or it will automatically expire and be of no value. Any other reward must be used within its specified redemption period (unless otherwise stated), or it will automatically expire and be of no value. Eggs may not be traded, sold or otherwise transferred to others or their accounts. Eggs may not be combined or earned with other discounts. Eggs are not replaced in your Egg N Bird Rewards account for expired rewards, so be sure to redeem each of your rewards before it expires. Egg N Bird Rewards may be combined with other Egg N Bird Rewards but not with other discounts or offers. Unless otherwise required or prohibited by applicable law, Eggs and rewards have no cash value and may not be redeemed for cash. Participation in this program constitutes acceptance of these terms and conditions and all others on our website. See eggnbird.com/rewards for details and all other Terms and Conditions of Loyalty and Gift Cards. All terms and conditions subject to change by posting at eggnbird.com/rewards.
                </p>
                <p>Questions? You may contact us at our website above.</p>
                <p>© 2023 Egg N Bird. All rights reserved. </p>
                <p>Egg N Bird Rewards are trademarks of Yogurtland Franchising, Inc.</p>
              </div>

              <div className="terms-section">
                <h3>Egg N Bird Rewards CARD ACCOUNT AND MOBILE APP AGREEMENT -- TERMS AND CONDITIONS</h3>
                <p>The following Egg N Bird Rewards Card Account and Mobile App Agreement describes the terms and conditions that apply to Egg N Bird Rewards Card Account (sometimes referred to below as an "Egg N Bird Rewards Card", the "Card" or "your Card," or “your account” and refers to both physical Cards and digital Cards that were registered or an account number that were provided to you when you registered online or using the Egg N Bird mobile app). As indicated below, certain of these terms and conditions also apply to the prepaid accounts of Egg N Bird gift cards. By using your Egg N Bird Rewards Card or account, any other Egg N Bird gift card, or the Egg N Bird mobile app, you confirm your acceptance of all of the terms and conditions of this agreement ("these Terms and Conditions"). Please keep a copy of this agreement for your records.</p>
              </div>

              <div className="terms-section">
                <h3>About Your Account</h3>
                <p>The Egg N Bird Rewards Card is issued by Yogurtland Franchising, Inc. The Card serves both as a loyalty card, allowing you to earn Eggs and Rewards through your eligible purchases, and as a stored value/gift card, allowing you to load U.S. Dollar value onto the card and then use it for purchases at participating Egg N Bird Stores. The dollar value that you load onto your Card is a prepayment only for the goods and services of participating stores. Except as described immediately below, no credit card, credit line, overdraft protection or deposit account is associated with an Egg N Bird Rewards Card or other Egg N Bird gift card. In the process of loading dollar value onto an Egg N Bird Rewards or other Card, a participant will have the option to link the participant's credit card to his or her Egg N Bird Rewards Card in order to add additional dollar value onto the Card at a later time, and to set up his or her account to either automatically add dollar value once the account's balance drops below a specified amount or to add additional amounts only by specific direction of participant on subsequent visits to our website at eggnbird.com/rewards.</p>
                <p>Except as otherwise required by law or expressly permitted by this agreement, any amount on your Card is nonrefundable and may not be redeemed for cash. No interest, dividends or any other earnings on funds deposited to an Egg N Bird Rewards Card or other gift card will accrue or be paid or credited to you by Egg N Bird.</p>
                <p>Most Egg N Bird Stores in the United States will accept your Egg N Bird Rewards Card and other authorized Egg N Bird gift cards. Certain Egg N Bird-branded locations might not permit you to use the Egg N Bird Rewards Card or other gift card for payment. We reserve the right not to accept any Egg N Bird Rewards Card or other gift card or otherwise limit use of such a card, if we reasonably believe that the use is unauthorized, fraudulent or otherwise unlawful.</p>
                <h6>These Terms and Conditions contain disclaimers and other provisions that limit our liability to you.</h6>
              </div>

              <div className="terms-section">
                <h3>Eligibility, Registration and Account</h3>
                <p>The Egg N Bird Rewards program and other Egg N Bird gift card and mobile app programs and Egg N Bird websites (the "Sites") are not intended for use by anyone under the age of 13 years. If you are between the ages of 13 and 18, you may only use the programs or the Sites under the supervision of a parent or legal guardian who agrees to be bound by these Terms and Conditions.</p>
                <p>In order to participate in the Egg N Bird Rewards program and certain areas of our Sites and to redeem rewards, you will need to register for an account. Registration may be completed on our mobile app or on our Sites. Registration includes providing us with certain information about you, so that we can keep track of your purchases and reward you. In completing your registration, you are also required to confirm your acceptance of these Terms and Conditions.</p>
                <p>Your use of any of the programs or Sites constitutes your agreement to (a) create only one account; (b) provide accurate, truthful, current and complete information when creating your account; (c) promptly update your account information; (d) help maintain the security of your account by not sharing your password with others and restricting access to your account and your computer; (e) promptly notify Egg N Bird if you discover or otherwise suspect any security breaches relating to the Sites; and (f) take responsibility for all activities that occur under your account and accept all risks of unauthorized access.</p>
              </div>

              <div className="terms-section">
                <h3>Egg N Bird Rewards PROGRAM, EGGS AND REWARDS</h3>
                <p>By registering and participating in Egg N Bird's Egg N Bird Rewards program, you can accumulate Eggs that allow you to earn Egg N Bird Rewards at participating Egg N Bird Stores.</p>
                <p>See below for redemption and expiration details.</p>
                <p>Eggs may not be traded, sold or otherwise transferred to others or their accounts. Each purchase is tied to the Egg N Bird Rewards account number provided to the cashier at the time of purchase, and only that account will earn Eggs from that purchase. Eggs may not be combined or earned with other discounts. All programs, promotions, and offers are subject to change by Egg N Bird without notice other than by posting on one of the Sites. Unless otherwise required or prohibited by applicable law, Eggs have no cash value and may not be redeemed for cash.</p>
                <p>Yearly Eggs are the total number of Eggs you earn in a single year from January 1 – December 31. Yearly Eggs will indicate your current tier and the next year’s tier. Yearly Eggs reset once a year on January 1st.</p>
                <p>Core Eggs (or Eggs Toward Next Reward) always reset at 100 and therefore does not necessarily match yearly Eggs. Core Eggs do not indicate tier status. Each time 100 Core Eggs are earned, a $5 Reward will be issued to your account.</p>
                <p>Bonus Eggs are earned from special offers or campaigns (ex: 2x Eggs, 3x Eggs, or 25 Bonus Eggs) and do not qualify as Yearly Eggs to move you closer to a new tier. Bonus Eggs do get you closer to earning your next reward and will be reflected on your Core Eggs/Eggs Toward Next Reward.</p>
              </div>


              <div className="terms-section">
                <h3>Enrollment & Registration (They're not the same thing.)</h3>
                <p>Enrollment. On and after the Program's start date, you enroll automatically with your first purchase of Eligible Product using your Egg N Bird Rewards Card. "Eligible Product" any food or beverage item sold at participating stores. You must use your Egg N Bird Rewards Card or mobile app to enroll. With your enrollment in our Egg N Bird Rewards program, you start earning Eggs for each Eligible Product that you purchase.</p>
                <p>Tier levels are based on Yearly Eggs earned from purchasing Eligible Product. To maintain your current tier status, you must earn 200 Yearly Eggs for Hot Tier or 600 Yearly Eggs for Eggstra Hot Tier between January 1 and December 31 of that current year.</p>
                <div>
                  <p>Registration. Registration includes providing us with certain information about you, so that we can keep track of your purchases and reward you. In completing your registration, follow the instruction below that fits your situation:</p>
                  <List 
                  bulleted
                  items={[
                    'If you have a physical Egg N Bird Rewards card with a unique number imprinted on it, you can follow the instructions on the promotional materials at our participating stores to register.',
                    'You can register for a digital Egg N Bird Rewards card (a mobile app account) by downloading the Egg N Bird mobile app and following the Egg N Bird Rewards registration prompts.'
                  ]} />
                  <p>
                 <strong> Earning Eggs and Rewards </strong> — For every dollar spent on Eligible Product purchased under the participant's Egg N Bird Rewards account at or after his or her enrollment (so long as enrollment is not more than 364 days prior to his or her registration date), the participant earns two Eggs. To make sure all of your purchases are counted, be sure to provide your physical or digital Card to the cashier at the time of purchase. If you forget to provide your Card, you may still be able to get Eggs for your purchase. To do so, you will need to contact our Guest Services team at customerservice@eggnbird.com. Please attach an image of your receipt and provide your account number. Please note that you are only able to recover Eggs within 30 days of the transaction date. We will need to know your name, Egg N Bird Rewards account number, the transaction number on your receipt, the date of purchase, the location of purchase, and the total amount spent (all except your name and account number will be printed on your receipt). We reserve the right to limit in our sole discretion the amount of Eggs you may be awarded using this procedure. You may also earn Eggs from purchases using Egg N Bird online ordering through the Egg N Bird website or mobile app.
                  </p>
                  <p>
                  We measure and account for each of your purchases to the nearest dollar. See below for details. (Redemption of rewards for Eligible Product does not earn Eggs; only purchases of Eligible Product earn Eggs.)
                  </p>
                  <p><strong>Three Levels of Rewards</strong> - There are three different levels of rewards.</p>
                  <p>
                    <strong>a. Mild Level</strong> -- Simply by registering with the Egg N Bird Rewards program, you are admitted to the Mild Level. This entitles you to 50 Bonus Eggs right away – halfway through to your first $5 Reward.
Also, if your birthday occurs while you are in the Mild Level, you will receive a birthday reward. We will send you an email notifying you of your birthday reward and of the four-week period in which it may be redeemed. If not redeemed during that four-week period, the reward will automatically expire. See below for details.
                  </p>
                  <p>
                  While at the Mild Level, with every purchase of Eligible Product from participating stores during your first year of registration (or during any subsequent year of registration), you will be moving closer to reaching the Hot Level. All it takes is earning 200 Yearly Eggs by December 31 of each year.
                  </p>
                  <p><strong>b. Hot Level</strong> -- After earning 200 total Eggs from Eligible Product purchases between January 1 to December 31 of the same year, you will achieve the Hot Level. Upon reaching the Hot Level, you will be qualified for Quarterly Bonus Eggs, a Birthday Reward Upgrade and a Half Birthday Reward. </p>
                  <p>Also, if your birthday occurs while you are in the Hot Level, you will receive a birthday reward. We will send you an email notifying you of your birthday reward and of the four-week period in which it may be redeemed. If not redeemed during that four-week period, the reward will automatically expire. See below for details. To make sure there's no misunderstanding, as a registered participant, you'll be eligible for only one birthday redemption per calendar year (not 2 or 3, even if you move through different levels in the same year).</p>
                  <p><strong>c. Eggstra Hot Level </strong> -- </p>
                  <p><strong></strong> -- After earning 600 total Eggs from Eligible Product purchases, you will achieve the Eggstra Hot Level. Upon reaching the Eggstra Hot Level, you will be qualified for Quarterly Bonus Eggs, a Birthday Reward Upgrade, a Half Birthday Reward, 2x Eggs every 2nd of the month and exclusive surprise offers throughout the year. Each time you receive a new reward, we'll send you an email to let you know that you have a new reward available to you.</p>
                 <p>Also, if your birthday occurs while you are in the Eggstra Hot Level, you will receive a birthday reward. We will send you an email notifying you of your birthday reward and of the four-week period in which it may be redeemed. If not redeemed during that four-week period, the reward will automatically expire. See below for details. To make sure there's no misunderstanding, as a registered participant, you'll be eligible for only one birthday redemption per calendar year (not 2 or 3, even if you move through different levels in the same year).</p>
                  <p><strong>Tracking Eggs and Maintaining Tier Levels</strong> -- Starting with your Enrollment Date, the program keeps track of your total Eggs from Eligible Product purchased through your Registration Date and up to/including the day before December 31 of the same year. Once you reach the Hot Level, you will keep your Hot Level status through that particular year and continuing until December 31 of the immediately following year, unless in the mean time you move to the Eggstra Hot Level.</p>
                  <div>
                    <p>
                    EXAMPLE 1: A participant registered on July 1, 2023 and achieved Hot Level on September 30, 2023 by surpassing 200 yearly Eggs.
                    </p>
                    <ul>
                      <li>If by the end of 2023 the guest does not reach 600 Eggs:</li>
                      <ul>
                        <li>On January 1st, 2024, the lifetime visit wallet will be reset to zero and the guests will maintain Hot status until the end of 2023 with no further action required.</li>
                        <ul>
                          <li>If the guests earn less than 200 Eggs in 2023, they will be reset to Mild on January 1st, 2024</li>
                          <li>If the guests earn 200-599 Eggs in 2023, they will remain Hot through 2024</li>
                          <li>If the guest earns 600+ Eggs in 2023, they will earn Eggstra Hot on the purchase that surpassed 600 yearly Eggs and remain Eggstra Hot through 2024.</li>
                        </ul>
                      </ul>
                    </ul>

                    <ul>
                      <li>If this guest reaches 600 yearly Eggs by the end of 2023, they will be upgraded to Eggstra Hot on the purchase that surpassed 600 yearly Eggs</li>
                      <ul>
                        <li>On January 1st, 2024, the lifetime visit wallet will be reset to zero and the guests will maintain Eggstra Hot status until the end of 2023 with no further action required.</li>
                        <ul>
                          <li>If the guests earn less than 200 Eggs in 2023, they will be reset to Mild on January 1st, 2024</li>
                          <li>If the guests earn 200-599 Eggs in 2023, they will be downgraded to Hot through 2024</li>
                          <li>If the guests earn 600+ Eggs in 2023, they will remain Eggstra Hot through 2024.</li>
                        </ul>
                      </ul>
                    </ul>
                  </div>
                  <div>
                    <p>
                    EXAMPLE 2: A participant registered on July 1, 2023 and earned less than 200 yearly Eggs by the end of the year.
                    </p>
                    <ul>
                      <li>On January 1st, 2024, the lifetime visit wallet will be reset to zero and the guests will maintain Mild status.</li>
                    </ul>
                  </div>
                  <p>All tier upgrades happen live as Egg thresholds are achieved.  Tier downgrades where applicable only happen once a year on January 1st.</p>
                  <p><strong>Expiration of Eggs</strong> -- Core Eggs you have accumulated do not expire or disappear, except by conversion of Eggs into Egg N Bird Rewards.  Yearly Eggs reset once a year on January 1 but do not affect the balance of Core Eggs.</p>
                </div>
              </div>

              <div className="terms-section">
                <h3>Expiration of Rewards</h3>
                <p><strong>Birthday Reward</strong> -- Once registered, a participant will receive an email sometime on or before his or her birthday. The email will notify the participant of a (generally) four-week period when he or she may redeem the participant's birthday reward for that calendar year. If the reward is not redeemed within the specified period, it will automatically expire and be of no redemption, cash or other value. Eggs are not replaced in your Egg N Bird Rewards account for expired rewards. Eggs are not replaced in your Egg N Bird Rewards account for expired rewards.</p>
                <p><strong>Other Egg N Bird Rewards</strong> -- Most Egg N Bird Rewards expire automatically on the 28th day after being issued. A $5.00 reward is automatically issued to your account every time you earn 100 Eggs. Eggs are not replaced in your Egg N Bird Rewards account for expired rewards. If you redeem your $5.00 reward and do not use the entire balance, the remaining balance will be available for use until the expiration date</p>
                <p><strong>Other Things to Know</strong> -- There are no membership fees associated with our Egg N Bird Rewards program. You may have only one account, and only individuals may have an account. If we determine that a participant has violated any of the terms or conditions of the program or pertaining to the cards, then we may terminate that participant's membership and account, with or without notice. We may also terminate an account without notice in the event we determine that the account is being used without proper authorization, fraudulently, or in any way inappropriately or unlawfully. We also reserve the right to cancel, terminate, suspend, or modify the Egg N Bird Rewards program at any time in our sole discretion and without notice.</p>
              </div>

              <div className="terms-section">
                <h3>Loading Dollar Value onto Your Egg N Bird Rewards Card</h3>
                <p>In the United States, you can load U.S. dollar value onto the Egg N Bird Rewards Card by using a credit card, debit card or cash at any participating store. You may not load more than U.S. $500-- worth of value onto your Card. The minimum dollar amount that may be loaded onto your Egg N Bird Rewards Card may vary at participating Stores. In addition, Egg N Bird may change the maximum and minimum amounts at participating Stores. All amounts loaded onto your Card are held and denominated in U.S. dollars.</p>
              </div>

              <div className="terms-section">
                <h3>Fees and Expiration of Card Balances</h3>
                <p>For Egg N Bird Rewards Cards purchased at participating Stores, Egg N Bird does not charge any fees for the issuance, activation or use. There is also no charge for digital cards purchased through the Egg N Bird mobile App or online.</p>
                <p>The paid-in monetary value of your Egg N Bird Rewards Card has no expiration date.</p>
              </div>

              <div className="terms-section">
                <h3></h3>
                <p></p>
                <p></p>
                <p></p>
              </div>

              <div className="terms-section">
                <h3>Receipts and Statements</h3>
                <p>Cardholders are not sent statements of itemized transactions from an Egg N Bird Rewards Card account or otherwise. You can check the balance of your Card or review recent transactions on your Card at eggnbird.com/rewards. You will need to have your Card available in order to access your account. If requested by you, the account balance for an Egg N Bird Rewards Card also will appear on your receipt from a point-of-sale register at a participating Store. When you use your Card, you will receive a receipt if you request one but will not be asked to sign the receipt. The receipt will indicate that the purchase was made using an Egg N Bird Rewards Card. You should keep your receipts and check your online statement to ensure that your account balance is correct.</p>
                <p></p>
                <p></p>
              </div>

              <div className="terms-section">
                <h3>Errors and Corrections</h3>
                <p>We reserve the right to correct the balance of your Egg N Bird Rewards Card account, if we believe that a clerical, billing, computing, or accounting error has occurred. If you have questions regarding your transaction history or any correction, or if you dispute any transaction or correction that has been made against your Card, please email us at customerservice@eggnbird.com. We will review the situation and correct any error that we verify. If no error was found, we will communicate an explanation. We shall have no liability for any clerical, billing, computing or accounting error, unless you provide us notice within 60 days of the date of the transaction in question. You should monitor your transactions and account balances closely.</p>
              </div>

              <div className="terms-section">
                <h3>Registration, Liability for Unauthorized Transactions</h3>
                <p>Because your Egg N Bird Rewards Card may be used like cash for purchases from participating Egg N Bird, stores, you are responsible for all transactions involving your Card, including unauthorized transactions. However, if your Egg N Bird Rewards Card is lost, stolen or destroyed, the Card can be replaced with the balance remaining on it at the time you contact us, but only if you have registered it with us. To register your Card, please visit us at eggnbird.com/rewards. If any of your registration information changes, you may make updates at eggnbird.com/rewards or the Egg N Bird mobile app.</p>
                <p>If your Egg N Bird Rewards Card becomes lost, stolen or damaged, you should email us immediately customerservice@eggnbird.com. Your Card balance is only protected from the point in time you notify us that your Card is missing. We will freeze the remaining balance on your Card once you notify us and will load that remaining balance on a replacement Egg N Bird Rewards Card.</p>
              </div>

              <div className="terms-section">
                <h3>Privacy Statement</h3>
                <p>For information concerning how we collect, use and disclose information concerning the Egg N Bird Rewards Card and how to select privacy preferences regarding certain promotional communications, you should refer</p>
              </div>

              <div className="terms-section">
                <h3>Changes to this Agreement</h3>
                <p>We may amend the terms of this agreement at any time, including any rights or obligations you or we may have. If you have registered your Egg N Bird Rewards Card, we will notify you of any change, addition or deletion by email. In addition, we will post the terms of the modified agreement on our website. As permitted by applicable law, any change, addition or deletion will become effective at the time we post the revised agreement to our website or as otherwise stated in our notice to you. Unless we then state otherwise, the change, addition or deletion will apply to your then existing and any subsequent Egg N Bird Rewards Cards. You are deemed to accept the changes, additions or deletions if (1) you do not notify us to the contrary in writing within 20 days of the date of our notice or such other time specified in the notice, or (2) you use your Card after such notice period. If you do not accept the changes, additions or deletions, your Egg N Bird Rewards Card will be cancelled and any amounts remaining on your Card will be refunded to you.</p>
              </div>

              <div className="terms-section">
                <h3>Cancellation of this Agreement</h3>
                <p>We may suspend or terminate this agreement and revoke or limit any or all of the rights and privileges granted to you at any time without notice or liability. Termination may result from your fraudulent or unauthorized use of the Egg N Bird Rewards Card. If we terminate this agreement without cause, we will refund or issue store credits equal to the balance held in your Card account less any amounts that you may owe us.</p>
                <p></p>
              </div>
              
              <div className="terms-section">
                <h3>Binding Arbitration</h3>
                <h6>Please read this section carefully. It affects rights that you may otherwise have. It provides for resolution of most disputes through arbitration instead of court trials and class actions. Arbitration is final and binding and subject to only very limited review by a court. This arbitration clause shall survive termination of this agreement.</h6>
                <p>This provision is to be construed broadly to cover all disputes or claims arising out of our relationship. Any dispute or claim made by you against us (or against any of our subsidiary, parent or affiliate companies or owners) arising out of or relating to this agreement or your use of any Egg N Bird Rewards Card or other Egg N Bird gift card (whether based in contract, tort, statute, fraud, misrepresentation or any other legal theory) will be resolved by binding arbitration except that (a) you may take claims to small claims court if they qualify for hearing by such a court, or (b) you or we may choose to pursue claims in court if the claims relate solely to the collection of any debts you owe to us. </p>
                <p>
                  <strong>
                  However, for any and all claims, even for those claims that may be taken to court, you and we both waive any claims for punitive or exemplary damages and any right to pursue claims on a class or representative basis.
                  </strong>
                </p>
              </div>

              <div className="terms-section">
                <h3>Arbitration Procedures</h3>
                <p>You must first present any claim or dispute to us by contacting our Customer Service department to allow us a reasonable opportunity to resolve the dispute. You may request arbitration if your claim or dispute is not resolved within 60 days. The arbitration of any dispute or claim shall be conducted in accordance with the American Arbitration Association ("AAA") Rules as modified by this agreement. The AAA Rules and information about arbitration and fees are available upon request from the AAA (call 1-800-778-7879) or online at adr.org. You and we agree that this agreement evidences a transaction in interstate commerce and this arbitration provision will be interpreted and enforced in accordance with the U.S. Federal Arbitration Act and federal arbitration law. Unless you and we agree otherwise in writing, any arbitration will take place in Orange County, California, and will be conducted in the English language. No arbitrator may award relief in excess of or contrary to what this agreement provides, order consolidation or arbitration on a class wide or representative basis, or award punitive or exemplary damages or any other damages aside from the prevailing party's actual damages, except that the arbitrator may award on an individual basis (and not on any class or group basis) damages required by statute and may order injunctive or declaratory relief pursuant to an applicable consumer protection statute. Each arbitration shall be confidential, and neither you nor we may disclose the existence, content or results of any arbitration, except as may be required by law or for purposes of enforcement of the arbitration award. Judgment on any arbitration award may be entered in any court having proper jurisdiction. If any portion of this arbitration clause is determined by a court to be inapplicable or invalid, then the remainder shall still be given full force and effect.</p>
              </div>

              <div className="terms-section">
                <h3>Costs of Arbitration</h3>
                <p>All administrative fees and expenses of an arbitration will be divided equally between you and us, except that for claims of less than $1,000, you will be obligated to pay $25 and we will pay all other administrative costs and fees. In all arbitrations, each party will bear the expense of its own counsel, experts, witnesses, and preparation and presentation of evidence at the arbitration.</p>
              </div>

              <div className="terms-section">
                <h3>Waiver of Punitive and Exemplary Damage Claims and Class Actions</h3>
                <p>By this Agreement, both you and we are waiving certain rights to litigate disputes in court. If for any reason this arbitration clause is deemed inapplicable or invalid, you and we both waive, to the fullest extent allowed by law, any claims to recover punitive or exemplary damages and any right to pursue any claims on a class or consolidated basis or in a representative capacity.</p>
              </div>

              <div className="terms-section">
                <h3>Governing Law — California</h3>
                <p>This Agreement shall be governed by and construed in accordance with the laws of the State of California notwithstanding any conflict of law rules.</p>
              </div>

              <div className="terms-section">
                <h3>Disclaimers and Limits of Liability</h3>
                <p><strong>Egg N Bird and its affiliates make no representations, warranties or conditions of any kind, express or implied, with respect to the Egg N Bird Rewards Card, including, but not limited to, any implied warranty of merchantability, fitness for a particular purpose, title, or non-infringement, or any warranty arising by usage of trade, course of dealing or course of performance. Egg N Bird does not represent or warrant that your Egg N Bird Rewards Card will always be accessible or accepted</strong></p>
                <p><strong>In the event that Egg N Bird or its affiliates are found liable to you, you shall only be entitled to recover actual and direct damages, and such damages shall not exceed the last balance held on your Egg N Bird Rewards Card. Egg N Bird and its affiliates shall have no liability for any incidental, indirect or consequential damages (including without limitation loss of profit, revenue or use) arising out of or in any way connected with your or any other person's ownership, possession, use, attempted use, condition, loss or destruction of any Egg N Bird Rewards Card or this agreement, whether in contract, warranty, tort (including negligence, whether active, passive or imputed), product liability, strict liability or other theory, even if we or our authorized representatives have been advised of the possibility of such damages. In no event shall Egg N Bird or its affiliates have any liability for unauthorized access to, or alteration, theft or destruction of an Egg N Bird Rewards Card through accident, misuse or fraudulent means or devices by you or any third party, or as a result of any delay or mistake resulting from any circumstances beyond our control.</strong></p>
                <p>The laws of certain states or other jurisdiction may not allow limitations on implied warranties, or the exclusion or limitation of certain damages. If these laws apply, some or all of the above disclaimers, exclusions or limitations may not apply to you, and you may have rights in addition to those contained in this agreement. In such jurisdiction, our liability is limited to the greatest extent permitted by applicable law.</p>
              </div>

              <div className="terms-section">
                <h3>Assignment</h3>
                <p>Acting in good faith, we may assign all or part of this agreement without such assignment being considered a change to this agreement, and without notice to you. We are then released from all liability. The assignee shall have the same rights and obligations as the assignor had immediately prior to such assignment and shall agree in writing to be bound by the terms and conditions of this agreement.</p>
              </div>

              <div className="terms-section">
                <h3>Entire Agreement, Construction</h3>
                <p>All of the terms and conditions of Egg N Bird's Loyalty/Gift Card, Mobile App & Website General Terms and Conditions (found on our website) are incorporated into this agreement.</p>
                <p>This agreement together with the other terms referred to in this agreement is the complete and exclusive statement of agreement between you and Yogurtland Franchising, Inc., and supersedes and merges all prior proposals and all other agreements. In the event that any provision of this agreement shall be determined to be illegal or unenforceable, that provision will be eliminated to the minimum extent necessary so that this agreement shall otherwise remain in full force and effect and enforceable. Headings in this agreement are for convenience of reference only and shall in no way affect interpretation of this agreement.</p>
              </div>

              <div className="terms-section">
                <h3>Questions</h3>
                <p>If you have any questions regarding this agreement or your Egg N Bird Rewards Card, please visit our web site at eggnbird.com/rewards.</p>
              </div>

              <div className="terms-section">
                <p>© 2023 Egg N Bird. All rights reserved. </p>
                <p>Egg N Bird and Egg N Bird Rewards are trademarks of Yogurtland Franchising, Inc.</p>
              </div>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          businessName
        }
      }
    }
  }
`;
